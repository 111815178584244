import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function LanguagueSwitcher() {
  const { i18n } = useTranslation()

  const { options: { supportedLngs, fallbackLng }, language } = i18n

  return (
    <>{supportedLngs.filter(l => l !== "cimode").map(lang => <Link to={lang === fallbackLng[0] ? "/" : `/${lang}/`}
                                    data-lang={lang}
                                    key={lang}
                                    className={`lang ${language === lang ? "active" : ""}`}
                                    onClick={() => i18n.changeLanguage(lang)}>{lang}</Link>)}</>
  )
}