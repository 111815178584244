import {useTranslation} from "react-i18next";
import { Redirect } from "react-router-dom"

export default function LocalizedLink({ to, ...props }) {
  const { i18n: { language, options: { fallbackLng } } } = useTranslation();

  const localizedTo = language === fallbackLng[0] ? to : `/${language}${to.startsWith("/") ? to : `/${to}`}`

  return (
    <Redirect to={localizedTo} {...props} />
  )
}