import { useCallback } from "react";

function SmoothAnchor({ href, offset = 50, children, ...props }) {
    const onClick = useCallback((event) => {
        event.preventDefault();
        const element = document.querySelector(href);
        const top = element.getBoundingClientRect().top + window.scrollY - offset;

        const toggleNavbar = document.querySelector('#toggle-navbar');
        toggleNavbar.checked = false;
        toggleNavbar.dispatchEvent(new Event('change'));

        document.documentElement.classList.remove("no-scroll");

        window.scrollTo({
            top,
            behavior: "smooth"
        });
    }, [offset, href]);

    return <a href={href} {...props} onClick={onClick}>{children}</a>
}

export default SmoothAnchor;
