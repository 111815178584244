import "./BlueLink.css";
import { Link } from "react-router-dom";

function BlueLink({ icon, children, ...props }) {
    return <div className="blue-link-container">
        <a className={`blue-link blue-link-${icon}`} {...props}>
            {children}
        </a>
    </div>
}

function BlueLinkRouted({ icon, children, ...props }) {
    return <div className="blue-link-container">
        <Link className={`blue-link blue-link-${icon}`} {...props}>
            {children}
        </Link>
    </div>
}

export { BlueLinkRouted };
export default BlueLink;
