import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as path from "path";

const debug = false && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')

const langs = ["pl", "en", "de"]; //first is fallback lng

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag']
    },
    supportedLngs: langs,
    fallbackLng: langs[0],
    debug,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

function redirectOnInit(detectedLang) {
  const { origin, pathname } = window.location

  const pathnames = pathname.split('/').slice(1);
  const [fallbackLang, ...otherLangs] = langs;

  const langFromUrl = otherLangs.includes(pathnames[0]) ? pathnames[0] : fallbackLang;

  if(langFromUrl !== detectedLang) {
    let newPathname = detectedLang === fallbackLang ? '' : `${detectedLang}/`;

    newPathname = `${newPathname}${langFromUrl === fallbackLang ? pathnames.join('/') : pathnames.slice(1).join('/')}`

    //console.log(detectedLang, `${origin}/${newPathname}`)
    window.location.href = `${origin}/${newPathname}`;
  }

  i18n.off('languageChanged', redirectOnInit);
}

i18n.on('languageChanged', redirectOnInit);

export default i18n;
