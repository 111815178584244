import { useCallback } from "react"

export function OverlayClose({ player }) {
  const onOverlayClose = useCallback((event) => {
    event.preventDefault();
    document.body.classList.remove('overlay-open');
    player?.current?.stopVideo();
  }, [player])

  return <button className="overlay-close" onClick={onOverlayClose} />;
}