import Link from "../components/Localized/Link";
import SmoothAnchor from "../components/SmoothAnchor";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {Cookie} from "../components/Cookie";

const n_array = n => Array(n).fill().map((_, i) => i + 1)
const aboutus = n_array(4)

export default function NotFound() {
  const { t, i18n } = useTranslation()

  useEffect(() => { document.title = t('title.tos') }, [t, i18n.lang]);

  return (
    <>
      <nav  className="small-navbar">
        <div  className="container">
          <Link to="/"  className="navbar-link-logo">
            <img src="/src/img/Eco-icon.svg" alt="ecoplanet24"/>
          </Link>
          <Link to="/"  className="navbar-go-back">{t`back_to_homepage`}</Link>
        </div>
      </nav>
      <main  className="container">
        <div className="nf-outer">
          <h1>{t`not_found.page_not_found`}</h1>
          <h2>{t`not_found.page_not_found_desc`}</h2>
          <Link to="/" className="nf-button">{t`not_found.main_page`}</Link>
        </div>
        <section  className="section-footer">
          <div  className="footer-flex">
            <div  className="footer-address">
              <div  className="footer-address-content">
                <a href="https://goo.gl/maps/NAEvCX7EXXugKcoF6"  className="footer-address-office" target="_blank" rel="noreferrer">
                  {t`section_footer.office`}<br/>
                  <address className="ul">{t`section_footer.address`}</address>
                </a>
                <div  className="footer-address-warsaw">
                  Friendly Innovation sp. z o.o.<br/>
                  Warsaw Towers, ul. Sienna 39, 00-121 Warszawa
                </div>
                <div  className="footer-address-nip">
                  <span>DUNS&reg;:850702469</span>
                  <span>NIP:5252772029</span>
                  <span>REGON:381562330</span>
                  <span>KRS:0000759528</span>
                </div>
                <a href="mailto:office@friendlyinnovation.com"  className="footer-address-email">office@friendlyinnovation.com</a>
              </div>
            </div>
            <div  className="footer-whataboutism">
              <div  className="footer-whataboutism-content">
                <p itemProp="name">Friendly Innovation sp. z o.o.</p>
                <ul>
                  {aboutus.map(i => <li key={i} dangerouslySetInnerHTML={{__html: t(`section_footer.about_us_${i}`)}} />)}
                </ul>
              </div>
            </div>
          </div>
          <div  className="footer-flex">
            <div  className="footer-nav-left">
              <div  className="footer-nav-left-content">
                <a href="https://www.friendlyinnovation.com/">Friendly Innovation</a>
                <Link to="/app/privacy-policy/">{t`section_footer.privacy_policy`}</Link>
                <Link to="/app/terms-of-service/">{t`section_footer.tos`}</Link>
              </div>
            </div>
            <div  className="footer-nav-right">
              <div  className="footer-nav-right-content">
                <SmoothAnchor href="#top">{t`section_footer.up`}</SmoothAnchor>
              </div>
              <div className="footer-social">
                <a href="https://www.instagram.com/friendly.innovation/" target="_blank" rel="noreferrer">
                  <img src="/src/img/sm/insta.png" alt="Instagram"/>
                </a>
                <a href="https://www.facebook.com/friendly.innovation/" target="_blank" rel="noreferrer">
                  <img src="/src/img/sm/iconfinder_Rounded_Facebook_svg_5282541.svg" alt="Facebook"/>
                </a>
                <a href="https://www.linkedin.com/company/friendly-innovation-sp-z-o-o/" target="_blank"
                   rel="noreferrer">
                  <img src="/src/img/sm/iconfinder_linked_in_104828.svg" alt="LinkedIn"/>
                </a>
                <a href="https://g.page/friendly-Innovation/" target="_blank" rel="noreferrer">
                  <img src="/src/img/sm/iconfinder_google_4691557.svg" alt="Google"/>
                </a>
                <a href="https://www.youtube.com/channel/UC3AogcMTLnmQ91GR7f4HYsQ" target="_blank" rel="noreferrer">
                  <img src="/src/img/sm/iconfinder_BW_Youtube_glyph_svg_5305164.svg" alt="YouTube"/>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Cookie />
    </>
  )
}
