import {BrowserRouter, Switch, Route} from "react-router-dom";
import Home from "./pages/Home";
import TOS from "./pages/TOS"
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import {useTranslation} from "react-i18next";
import { useEffect } from "react"
import { useCallback } from "react"
import Contact from "./pages/Contact";
import Helmet from "react-helmet"

function App() {

  const { i18n: { language, options: { fallbackLng, supportedLngs } }, t } = useTranslation()

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language])

  const getLocalized = useCallback((path) => {
    return supportedLngs.map(lng => lng === fallbackLng[0] ? path : `/${lng}${path}`)
  }, [supportedLngs, fallbackLng])

  return (
    <>
      <Helmet>
        <meta name="description" content={t('meta.description')} />
        <meta name="keywords" content={t('meta.keywords')} />
      </Helmet>
      <BrowserRouter>
        <Switch>
          <Route exact path={getLocalized("/")} component={Home} />
          <Route exact path={getLocalized("/app/privacy-policy")} component={PrivacyPolicy} />
          <Route exact path={getLocalized("/app/terms-of-service")} component={TOS} />
          <Route exact path={getLocalized("/Contact")} component={Contact} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
