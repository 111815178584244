import "./styles.css";
import {useCallback, useState, useEffect, useLayoutEffect} from "react";
import cezary from "./cezary.png";
import Link from "../../components/Localized/Link"
import Redirect from "../../components/Localized/Redirect"

import {useWindowSize} from 'react-use';
import Confetti from 'react-confetti';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function Interest({ name, title }) {
    return <>
        <input type="checkbox" name={name} id={name}
               className="contact-input-interest" value="1"/>
        <label className="contact-label-interest" htmlFor={name}>
            {title}
        </label>
    </>;
}

function ContactForm({ onSubmit, disabled }) {
    const { t } = useTranslation()

    const interests = {
      interest_car_chargers: t`contact.interest.car_chargers`,
      interest_energy_balance: t`contact.interest.energy_balance`,
      interest_smart_home: t`contact.interest.smart_home`,
    }

    return <div className="contact-inner">
        <h1 className="contact-title">
            <Link to="/" className="contact-logo">
                <img src="/src/img/Eco-icon.svg" alt="Ecoplanet24"/>
            </Link>
            {t`contact.get_help`}
        </h1>
        <form className="contact-form" onSubmit={onSubmit}>
            <div className="contact-form-top">
                <div className="contact-form-top-inner">
                    <p>{t`contact.interested_in`}</p>
                    <div className="contact-form-interests">
                        {Object.entries(interests).map(([name, title]) => <Interest key={name} name={name} title={title} />)}
                    </div>
                </div>
            </div>
            <div className="contact-form-bottom">
                <div className="contact-form-bottom-left">
                    <div className="contact-bottom-sub-left">
                        <label className="contact-label">
                            {t`contact.name`}
                            <input type="name" name="name" className="contact-input" required/>
                        </label>
                        <label className="contact-label">
                            {t`contact.phone`}
                            <input type="tel" name="tel" className="contact-input" required/>
                        </label>
                        <label className="contact-label">
                            {t`contact.email`}
                            <input type="email" name="email" className="contact-input" required/>
                        </label>
                    </div>
                    <div className="contact-bottom-sub-right">
                        <label className="contact-label">
                            {t`contact.content`}<span className="faded">{t`contact.optional`}</span>
                            <textarea className="contact-input contact-textarea" name="message"/>
                        </label>
                        <input type="checkbox" name="terms" id="terms" className="contact-input-interest" required
                               value="1"/>
                        <label className="contact-checkbox" htmlFor="terms">
                            {t`contact.terms`}
                        </label>
                        <button className="contact-form-submit" disabled={disabled}>{t`contact.want_help`}</button>
                    </div>
                </div>
                <div className="contact-form-bottom-right">
                    <figure className="contact-person">
                        <img src={cezary} className="contact-img" alt="Cezary"/>
                        <figcaption className="contact-name">Cezary</figcaption>
                    </figure>
                    <a href="tel:608687168" className="contact-item contact-item-number">608 687 168</a>
                </div>
            </div>
        </form>
    </div>;
}

const countDownS = 30;

function ThankYou({ branding }) {
    const { width, height } = useWindowSize();
    const [timeLeft, setTimeleft] = useState(countDownS);
    const { t } = useTranslation()

    useEffect(() => {
        const start = Date.now();

        const id = setInterval(() => {
            setTimeleft(Math.max(0, countDownS - Math.round((Date.now() - start) / 1000)));
        }, 100);

        return () => clearInterval(id)
    }, [setTimeleft, branding])

    return <div className="contact-inner contact-thankyou">
        <Confetti
            style={{left: 0, position: "fixed"}}
            width={width}
            height={height}
        />
        <figure className="contact-thankyou-icon">✓</figure>
        <h1 className="contact-title">
            <Link to="/" className="contact-logo">
                <img src="/src/img/Eco-icon.svg" alt="Ecoplanet24"/>
            </Link>
            {t`contact.thanks_for_contact`}
        </h1>
        <h2>{t`contact.will_contact_you`}</h2>
        <Link className="contact-form-submit" to="/">{t(`contact.go_to_main`, {timeLeft})}</Link>
        {timeLeft <= 0 && <Redirect to="/" />}
    </div>;
}

function Index() {
    const { t, language } = useTranslation()

    useLayoutEffect(() => {
        setTimeout(() => document.title = t('meta.title.contact'), 1000)
    }, [t, language])

    useEffect(() => {
        window?.userengage('event.open_contact');
        window.ga && window.ga('send', 'event', 'contact_form', 'opened');
    }, [])

    const [isSubmit, setIsSubmit] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const onFormSubmit = useCallback(async (event) => {
        setDisabled(true);

        const fd = new FormData(event.currentTarget);
        event.preventDefault();

        const form_data = {
            email: fd.get("email"),
            name: fd.get("name"),
            phone_number: fd.get("tel"),
            interest_car_chargers: !!fd.get("interest_car_chargers"),
            interest_energy_balance: !!fd.get("interest_energy_balance"),
            interest_smart_home: !!fd.get("interest_smart_home"),
            terms: !!fd.get("terms"),
            message: fd.get("message"),
            portal: "Ecoplanet24"
        };

        window.UE.pageHit({...form_data});
        window.ga && window.ga('send', 'event', 'contact_form', 'submit');
        await window.userengage('event.form_submit', form_data);

        setTimeout(() => {
            setIsSubmit(true);
        }, 1500);
    }, []);

    return <>
        <Helmet>
            <title>{t('meta.title.contact')}</title>
        </Helmet>
        <div className="contact-outer">
        {!isSubmit ? <ContactForm onSubmit={onFormSubmit} disabled={disabled}/> : <ThankYou />}
    </div></>;
}

export default Index;
