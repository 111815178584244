import "./VideoButton.css"
import {useTranslation} from "react-i18next";
import { useCallback } from "react"

function VideoButton({ player }) {
  const { t } = useTranslation()

  const onOpenVideo = useCallback((event) => {
    event.preventDefault();
    document.body.classList.add('overlay-open');
    player?.current?.playVideo();
  }, [player])

  return (
    <a href="https://youtu.be/sBrsQiSEtFI" target="_blank" rel="noreferrer" className="video-button" onClick={onOpenVideo} >{t`video_button`}</a>
  );
}

export { VideoButton }