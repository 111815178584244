import {VideoButton} from "../components/VideoButton";
import {useTranslation} from "react-i18next";
import {OverlayClose} from "../components/OverlayClose";
import {NavbarToggle} from "../components/NavbarToggle";
import {Opinions} from "../components/Opinions"

import { useCallback, useEffect, useLayoutEffect } from "react"
import SmoothAnchor from "../components/SmoothAnchor";
import {WOW} from "wowjs";
import lax from 'lax.js';
import {useRef} from "react";
import YouTube from "react-youtube";
import SectionSmartHome from "../components/SectionSmartHome";
import Link from "../components/Localized/Link"
import LanguagueSwitcher from "../components/Localized/Switcher";
import {Cookie} from "../components/Cookie";
import {Helmet} from "react-helmet";

const n_array = n => Array(n).fill().map((_, i) => i + 1)
const rotators = n_array(6)
const aboutus = n_array(4)

function Home() {
  const { t, language } = useTranslation()

  const playerRef = useRef(null)

  useEffect(() => {
    new WOW().init();

    lax.setup(); // init

    const updateLax = () => {
      lax.update(window.scrollY);
      window.requestAnimationFrame(updateLax);
    }

    window.requestAnimationFrame(updateLax);
  }, [])

  const onCollapsibleRef = useCallback((ref) => {
    if(!ref) return

    const observer = new ResizeObserver(() => ref.style.height = `${ref.children[0].offsetHeight}px`)
    observer.observe(ref)

    return () => observer.unobserve(ref)
  }, [])

  const onNavClick = useCallback(() => {
    document.documentElement.classList.remove('no-scroll');
  }, [])

  useLayoutEffect(() => {
    setTimeout(() => document.title = t('meta.title.home'), 1000)
  }, [t, language])

  return (
    <>
      <Helmet defer={true}>
        <title>{t('meta.title.home')}</title>
        <link rel="alternate" hrefLang="pl" href="https://www.ecoplanet24.com/"/>
        <link rel="alternate" hrefLang="en" href="https://www.ecoplanet24.com/en/"/>
        <link rel="alternate" hrefLang="de" href="https://www.ecoplanet24.com/de/"/>
      </Helmet>
      <nav className="fixed-navbar lax" data-lax-translate-y="100 0, 200 elh">
        <div className="navbar-content">
          <ul className="navbar-links">
            <li className="navbar-link-logo">
              <Link onClick={onNavClick} to="/">
                <img src="/src/img/Eco-icon.svg" alt="Ecoplanet24"/>
              </Link>
            </li>
            <li className="navbar-link"><SmoothAnchor href="#product">{t`navbar.our_product`}</SmoothAnchor></li>
            <li className="navbar-link"><a href="https://www.friendlyinnovation.com">{t`navbar.about_us`}</a></li>
            <li className="navbar-link"><Link onClick={onNavClick} to="/contact">{t`navbar.contact`}</Link>
            </li>
            <li className="navbar-link">
              <LanguagueSwitcher/>
            </li>
          </ul>
        </div>
      </nav>
      <NavbarToggle/>
      <nav className="mobile-navbar">
        <Link onClick={onNavClick} to="/" className="navbar-link-logo">
          <img src="/src/img/Eco-icon.svg" alt="Ecoplanet24"/>
        </Link>
        <label htmlFor="toggle-navbar" className="toggle-navbar-button">
          <span/>
          <span/>
        </label>
        <ul className="navbar-links">
          <li className="navbar-link"><SmoothAnchor href="#product">{t`navbar.our_product`}</SmoothAnchor></li>
          <li className="navbar-link"><a href="https://www.friendlyinnovation.com">{t`navbar.about_us`}</a></li>
          <li className="navbar-link"><Link onClick={onNavClick} to="/contact">{t`navbar.contact`}</Link>
          </li>
          <li className="navbar-link-language">
            <LanguagueSwitcher/>
          </li>
        </ul>
      </nav>
      <main id="top">
        <nav className="main-navbar">
          <div className="navbar-content container">
            <ul className="navbar-links">
              <li className="navbar-link-logo">
                <Link onClick={onNavClick} to="/">
                  <img src="/src/img/Eco-icon.svg" alt="ecoplanet24"/>
                </Link>
              </li>
              <li className="navbar-link"><SmoothAnchor href="#product">{t`navbar.our_product`}</SmoothAnchor></li>
              <li className="navbar-link"><a href="https://www.friendlyinnovation.com">{t`navbar.about_us`}</a></li>
              <li className="navbar-link"><Link onClick={onNavClick} to="/contact">{t`navbar.contact`}</Link>
              </li>
              <li className="navbar-link-language">
                <LanguagueSwitcher/>
              </li>
            </ul>
            <ul className="rotators">
              {rotators.map(i => <li className={`rotator-${i}`} key={i}>
                <h1 className="title large"
                    dangerouslySetInnerHTML={{"__html": t(`rotators.${i}`, {interpolation: {escapedValue: false}})}}/>
              </li>)}
            </ul>
          </div>
        </nav>
        <div className="sections container">
          <section className="section-intro" id="product">
            <div className="separator separator-produkt">
              <hr/>
              <h2 className="title"
                dangerouslySetInnerHTML={{"__html": t(`section_intro.complete_ecosystem`, {interpolation: {escapedValue: false}})}}
              />
            </div>
            <div className="section-intro-options">
              <div className="section-intro-option wow fadeInUp">
                <div className="section-intro-option-image">
                  <img src="/src/img/ladowarka.svg" className="ladowarka" alt={t`section_intro.charger`}/>
                </div>
                <p className="text"
                   dangerouslySetInnerHTML={{"__html": t(`section_intro.charger_desc`, {interpolation: {escapedValue: false}})}} />
              </div>
              <div className="section-intro-option-image wow fadeInUp" data-wow-delay=".2s">
                <img src="/src/img/+.svg" className="plus" alt=""/>
              </div>
              <div className="section-intro-option wow fadeInUp" data-wow-delay=".3s">
                <div className="section-intro-option-image">
                  <img src="/src/img/phone.svg" className="telefon" alt={t`section_intro.phone`}/>
                </div>
                <p className="text"
                   dangerouslySetInnerHTML={{"__html": t(`section_intro.app_desc`, {interpolation: {escapedValue: false}})}} />
              </div>
              <div className="section-intro-option-image wow fadeInUp" data-wow-delay=".5s">
                <img src="/src/img/=.svg" className="equal" alt=""/>
              </div>
              <div className="section-intro-option wow fadeInUp" data-wow-delay=".6s">
                <div className="section-intro-option-image">
                  <img src="/src/img/ECO.svg" className="listek" alt={t`section_intro.leaf`}/>
                </div>
                <p className="text"
                   dangerouslySetInnerHTML={{"__html": t(`section_intro.eco_desc`, {interpolation: {escapedValue: false}})}} />
              </div>
            </div>
            <div className="section-intro-possibilities row">
              <div className="column column-2">
                <hr/>
                <h2 className="title large mobile-only">{t`section_intro.possibilities`}</h2>
                <img src="/src/img/ladowarki.jpg" className="ladowarki wow fadeInUp" alt={t`section_intro.chargers`}/>
                <div className="text-box wow fadeInUp">
                  <h3 className="title small">{t`section_intro.power_possibilities`}</h3>
                  <p className="text">{t`section_intro.power_possibilities_p1`}</p>
                  <p className="text">{t`section_intro.power_possibilities_p2`}</p>
                </div>
              </div>
              <div className="column column-1">
                <h2 className="title large desktop-only">{t`section_intro.possibilities_title2`}</h2>
                <img src="/src/img/pojazdy.jpg" className="pojazdy wow fadeInUp" alt={t`section_intro.vehicles`}/>
                <div className="text-box wow fadeInUp">
                  <h3 className="title small">{t`section_intro.for_any_vehicle`}</h3>
                  <p className="text">{t`section_intro.vehicle_charger_desc`}</p>
                </div>
              </div>
            </div>
          </section>
          <section className="section-specs collapsible compact">
            <input type="checkbox" className="collapsible-checkbox" id="col-specs"/>
            <label htmlFor="col-specs" className="collapsible-button" />
            <div className="collapsible-container" ref={onCollapsibleRef}>
              <div className="collapsible-content">
                <div className="row">
                  <div className="column column-1" />
                  <div className="column column-2">
                    <div className="text-box">
                      <h3 className="title small">{t`section_specs.data_sheet`}</h3>
                      <p className="text">{t`section_specs.data_sheet_desc`}</p>
                    </div>
                  </div>
                </div>
                <div className="sections-specs-picker">
                  <input type="radio" name="type" value="ladowarka" className="picker-checkbox" id="type_ladowarka"
                         defaultChecked={true}/>
                  <input type="radio" name="type" value="ladowarka" className="picker-checkbox" id="type_wallbox"/>
                  <div className="sections-specs-picker-menu">
                    <label className="picker-button" htmlFor="type_ladowarka">{t`section_specs.station`}</label>
                    <label className="picker-button" htmlFor="type_wallbox">{t`section_specs.wallbox`}</label>
                  </div>
                  <div className="sections-specs-picker-ladowarka">
                    <table className="sections-specs-table">
                      <tbody>
                      <tr>
                        <td>{t`specs_picker.power_supply`}</td>
                        <td>{t`specs_picker.ac230`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.type`}</td>
                        <td>{t`specs_picker.ac`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.socket`}</td>
                        <td>{t`specs_picker.2xtype`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.socket_optional`}</td>
                        <td>{t`specs_picker.1xtype`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.input_power`}</td>
                        <td>{t`specs_picker.2064`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.output_power_single`}</td>
                        <td>{t`specs_picker.3672`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.dynamic_power`}</td>
                        <td>{t`specs_picker.yes`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.connector`}</td>
                        <td>{t`specs_picker.yes`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.connectivity`}</td>
                        <td>{t`specs_picker.wifi_blu`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.optional`}</td>
                        <td>{t`specs_picker.gprs`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.ac_dc`}</td>
                        <td>{t`specs_picker.iec`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.temperature`}</td>
                        <td>{t`specs_picker.3050C`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.api`}</td>
                        <td>{t`specs_picker.yes`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.ip_code`}</td>
                        <td>{t`specs_picker.ip55`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.casing`}</td>
                        <td>{t`specs_picker.aluminum`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.dimensions`}</td>
                        <td>{t`specs_picker.whd1`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.weight`}</td>
                        <td>{t`specs_picker.24kg`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.management`}</td>
                        <td>{t`specs_picker.yes`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.app`}</td>
                        <td>{t`specs_picker.yes`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.manufacturing`}</td>
                        <td className="UE">{t`specs_picker.eu`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.warranty`}</td>
                        <td>{t`specs_picker.24months`}</td>
                      </tr>
                      </tbody>
                    </table>
                    <img src="/src/img/stacjaladowania.png" className="stacja-ladowania" alt=""/>
                  </div>
                  <div className="sections-specs-picker-wallbox">
                    <table className="sections-specs-table">
                      <tbody>
                      <tr>
                        <td>{t`specs_picker.power_supply`}</td>
                        <td>{t`specs_picker.ac230`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.type`}</td>
                        <td>{t`specs_picker.ac`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.socket`}</td>
                        <td>{t`specs_picker.typ2m3`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.input_power`}</td>
                        <td>{t`specs_picker.1032a`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.output_power_single`}</td>
                        <td>{t`specs_picker.3672`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.connectivity`}</td>
                        <td>{t`specs_picker.wifi_blu`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.optional`}</td>
                        <td>{t`specs_picker.gprs`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.ac_dc`}</td>
                        <td>{t`specs_picker.iec`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.temperature`}</td>
                        <td>{t`specs_picker.3050C`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.api`}</td>
                        <td>{t`specs_picker.yes`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.ip_code`}</td>
                        <td>{t`specs_picker.ip56`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.casing`}</td>
                        <td>{t`specs_picker.polycarbonate`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.dimensions`}</td>
                        <td>{t`specs_picker.whd2`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.weight`}</td>
                        <td>{t`specs_picker.6kg`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.management`}</td>
                        <td>{t`specs_picker.yes`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.app`}</td>
                        <td>{t`specs_picker.yes`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.manufacturing`}</td>
                        <td className="UE">{t`specs_picker.eu`}</td>
                      </tr>
                      <tr>
                        <td>{t`specs_picker.warranty`}</td>
                        <td>{t`specs_picker.24months`}</td>
                      </tr>
                      </tbody>
                    </table>
                    <img src="/src/img/wallbox-ts.png" className="wallbox wallbox-ts" alt={t`specs_picker.wallbox`}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-potential">
            <div className="row">
              <div className="column column-1">
                <img src="/src/img/potencjal.png" className="potencjal wow fadeInUp" alt=""/>
              </div>
              <div className="column column-2">
                <div className="text-box wow fadeInUp">
                  <hr/>
                  <h3 className="title small">{t`section_potential.title1`}</h3>
                  <p className="text">{t`section_potential.desc1`}</p>
                  <VideoButton player={playerRef}/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="column column-2">
                <img src="/src/img/drzewo.jpg" className="potencjal wow fadeInUp" alt=""/>
              </div>
              <div className="column column-1">
                <div className="text-box wow fadeInUp">
                  <hr/>
                  <h3 className="title small green-darker">{t`section_potential.be_eco`}<img src="/src/img/listek.svg" className="listek" alt=""/>
                  </h3>
                  <p className="text">{t`section_potential.desc2a`}</p>
                  <p className="text">{t`section_potential.desc2b`}</p>
                </div>
              </div>
            </div>
          </section>
          <section className="section-energia collapsible compact">
            <input type="checkbox" className="collapsible-checkbox" id="col-energia"/>
            <label htmlFor="col-energia" className="collapsible-button green"/>
            <div className="collapsible-container" ref={onCollapsibleRef}>
              <div className="collapsible-content">
                <div className="row">
                  <div className="column column-1">
                    <div className="text-box mobile-only">
                      <h3 className="title small">{t`section_energia.title1`}</h3>
                      <p className="text">{t`section_energia.desc1`}</p>
                      <p className="text">{t`section_energia.desc2`}</p>
                    </div>
                    <div className="eco1-container">
                      <img src="/src/img/Eco1.png" className="Eco1" alt="Eco"/>
                      <div className="eco1-diagrams">
                        <div className="eco1-diagram">
                          <img src="/src/img/graph1.svg" className="mobile-only" alt="Surplus energy"/>
                          <h4 className="title small">{t`section_energia.surplus`}</h4>
                          <img src="/src/img/graph1.svg" className="desktop-only" alt="Surplus energy"/>
                          <p className="text">{t`section_energia.controlled_by`}</p>
                        </div>
                        <div className="eco1-diagram">
                          <img src="/src/img/graph2.svg" className="mobile-only" alt={t`section_energia.cheaper_rates`}/>
                          <h4 className="title small">{t`section_energia.cheaper_rates`}</h4>
                          <img src="/src/img/graph2.svg" className="desktop-only" alt={t`section_energia.cheaper_rates`}/>
                        </div>
                      </div>
                      <div className="eco1-lines">
                        <div className="eco1-lines1"/>
                        <div className="eco1-lines2"/>
                      </div>
                    </div>
                  </div>
                  <div className="column column-2">
                    <div className="text-box desktop-only">
                      <h3 className="title small">{t`section_energia.title1`}</h3>
                      <p className="text">{t`section_energia.desc1`}</p>
                      <p className="text">{t`section_energia.desc2`}</p>
                    </div>
                    <div className="section-energia-descs">
                      <div className="section-energia-desc">
                        <strong className="title smaller">{t`section_energia.charger`}</strong>
                        <p className="text">{t`section_energia.optimal_use`}</p>
                      </div>
                      <div className="section-energia-desc">
                        <strong className="title smaller">{t`section_energia.pv_panels`}</strong>
                        <p className="text">{t`section_energia.connect_panels`}</p>
                      </div>
                    </div>
                    <div className="text-box">
                      <p className="text">{t`section_energia.thanks_to`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-skrojona">
            <div className="separator separator-klienci">
              <hr className="desktop-only"/>
              <div className="text-box">
                <h3 className="title large wow fadeInUp"
                    dangerouslySetInnerHTML={{"__html": t(`section_skrojona.offer_tailored`, {interpolation: {escapedValue: false}})}} />
                <p className="text">{t`section_skrojona.offer_tailored_desc`}</p>
              </div>
            </div>
          </section>
          <section className="section-dla-siebie collapsible">
            <input type="checkbox" className="collapsible-checkbox" id="col-for-me"/>
            <label htmlFor="col-for-me" className="collapsible-header">
              <h2 className="title small">{t`section_skrojona.charger_for_my`}</h2>
            </label>
            <div className="collapsible-container" ref={onCollapsibleRef}>
              <div className="collapsible-content">
                <div className="row">
                  <div className="column column-1" itemScope itemType="http://schema.org/Product">
                    <div className="text-box">
                      <h3 className="title smaller">{t`section_skrojona.title1`}</h3>
                      <p className="text">{t`section_skrojona.desc1`}</p>
                    </div>
                    <figure className="wallbox">
                      <img src="/src/img/wallbox.png" alt="Wallbox" itemProp="image"/>
                      <div className="price">
                        <small>{t`section_skrojona.suggested_price`}</small>
                        <span itemProp="offers" itemType="http://schema.org/Offer" itemScope>
											<strong itemProp="price" content={`${t("section_skrojona.price1")}.00`}>{t`section_skrojona.price1`}</strong><span itemProp="priceCurrency"
                                                                                  content={t`section_skrojona.eur`}>{t`section_skrojona.eur`}</span>
											<meta itemProp="availability" content="https://schema.org/InStock"/>
										</span>
                      </div>
                    </figure>
                    <div className="text-box">
                      <h3 className="title smaller" itemProp="name">{t`section_skrojona.wallbox_charger`}</h3>
                      <p className="text" itemProp="description">{t`section_skrojona.wallbox_charger_desc`}</p>
                    </div>
                    <div itemProp="aggregateRating" itemType="http://schema.org/AggregateRating" itemScope>
                      <meta itemProp="reviewCount" content="91"/>
                      <meta itemProp="ratingValue" content="5.0"/>
                    </div>
                    <meta itemProp="brand" itemType="http://schema.org/Brand" content="Friendly Innovation"/>
                    <meta itemProp="sku" content="EVACW221"/>
                  </div>
                  <div className="column column-2" itemScope itemType="http://schema.org/Product">
                    <figure className="stacja-ladowania-large">
                      <img src="/src/img/stacjaladowania.png" alt="Charger" itemProp="image"/>
                      <div className="price">
                        <small>{t`section_skrojona.suggested_price`}</small>
                        <span itemProp="offers" itemType="http://schema.org/Offer" itemScope>
											<strong itemProp="price" content={`${t("section_skrojona.price2")}.00`}>{t`section_skrojona.price2`}</strong><span itemProp="priceCurrency"
                                                                                    content={t`section_skrojona.eur`}>{t`section_skrojona.eur`}</span>
											<meta itemProp="availability" content="https://schema.org/InStock"/>
										</span>
                      </div>
                    </figure>
                    <div className="text-box">
                      <h3 className="title smaller" itemProp="name">{t`section_skrojona.charging_station`}</h3>
                      <p className="text" itemProp="description">{t`section_skrojona.charging_station_desc`}</p>
                    </div>
                    <div itemProp="aggregateRating" itemType="http://schema.org/AggregateRating" itemScope>
                      <meta itemProp="reviewCount" content="101"/>
                      <meta itemProp="ratingValue" content="5.0"/>
                    </div>
                    <meta itemProp="brand" itemType="http://schema.org/Brand" content="Friendly Innovation"/>
                    <meta itemProp="sku" content="EVACS222"/>
                  </div>
                </div>
                <div className="button-container">
                  <strong className="title">{t`section_skrojona.buy_charger`}</strong>
                  <Link onClick={onNavClick} to="/contact" className="button">{t`section_skrojona.start`}</Link>
                </div>
              </div>
            </div>
          </section>
          <section className="section-wspoldzielenie collapsible">
            <input type="checkbox" className="collapsible-checkbox" id="col-for-sharing"/>
            <label htmlFor="col-for-sharing" className="collapsible-header">
              <h2 className="title small">{t`section_wspoldzielenie.want_build`}</h2>
            </label>
            <div className="collapsible-container" ref={onCollapsibleRef}>
              <div className="collapsible-content">
                <div className="row">
                  <div className="column column-1">
                    <div className="text-box">
                      <h3 className="title smaller">{t`section_wspoldzielenie.title1`}</h3>
                      <p className="text">{t`section_wspoldzielenie.desc1`}</p>
                    </div>
                  </div>
                </div>
                <figure className="diagram diagram-wspoldzielenie">
                  <img src="/src/img/wspoldzielenie.png" className="diagram-image wspoldzielenie desktop-only"
                       alt="Współdzielenie"/>
                  <img src="/src/img/wspoldzielenie-mobile.png" className="diagram-image wspoldzielenie mobile-only"
                       alt="Współdzielenie"/>
                  <div className="diagram-container">
                    <figcaption className="diagram-textbox">
                      <div className="diagram-badge top">
                        <img src="/src/img/listek.svg" className="listek" alt="Liść"/>{t`section_wspoldzielenie.eco`}
                      </div>
                      <h4 className="title smaller">{t`section_wspoldzielenie.orchardways`}</h4>
                      <p className="text">{t`section_wspoldzielenie.orchardways_desc`}</p>
                    </figcaption>
                    <figcaption className="diagram-textbox">
                      <h4 className="title smaller">{t`section_wspoldzielenie.downtown`}</h4>
                      <p className="text">{t`section_wspoldzielenie.downtown_desc`}</p>
                    </figcaption>
                    <figcaption className="diagram-textbox upward">
                      <div className="diagram-badge btm">
                        <img src="/src/img/listek.svg" className="listek" alt="Liść"/>{t`section_wspoldzielenie.eco`}
                      </div>
                      <h4 className="title smaller">{t`section_wspoldzielenie.home_charger`}</h4>
                      <p className="text">{t`section_wspoldzielenie.8am4pm`}</p>
                    </figcaption>
                    <figcaption className="diagram-textbox upward">
                      <h4 className="title smaller">{t`section_wspoldzielenie.municipal`}</h4>
                      <p className="text" dangerouslySetInnerHTML={{__html: t`section_wspoldzielenie.socket12`}} />
                    </figcaption>
                  </div>
                </figure>
                <div className="row">
                  <div className="column column-1">
                    <div className="text-box">
                      <h3 className="title smaller">{t`section_wspoldzielenie.manage_app`}</h3>
                      <p className="text">{t`section_wspoldzielenie.manage_app_desc`}</p>
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <strong className="title">{t`section_wspoldzielenie.build_infrastructure`}</strong>
                  <Link onClick={onNavClick} to="/contact" className="button">{t`section_wspoldzielenie.start`}</Link>
                </div>
              </div>
            </div>
          </section>
          <section className="section-ekologia collapsible">
            <input type="checkbox" className="collapsible-checkbox" id="col-for-energy"/>
            <label htmlFor="col-for-energy" className="collapsible-header">
              <h2 className="title small">{t`section_ekologia.care_about_environment`}</h2>
            </label>
            <div className="collapsible-container" ref={onCollapsibleRef}>
              <div className="collapsible-content">
                <div className="row">
                  <div className="column column-1">
                    <div className="text-box">
                      <h3 className="title smaller">{t`section_ekologia.title1`}</h3>
                      <p className="text">{t`section_ekologia.desc1`}</p>
                    </div>
                  </div>
                </div>
                <figure className="diagram diagram-ekologia">
                  <img src="/src/img/Diagram3.png" className="diagram-image Diagram3 desktop-only"
                       alt="Diagram ekologia"/>
                  <img src="/src/img/Diagram3-top.png" className="diagram-image Diagram3-top mobile-only"
                       alt="Diagram ekologia"/>
                  <div className="diagram-container">
                    <figcaption className="diagram-textbox mobile-left">
                      <h4 className="title smaller">{t`section_ekologia.storage`}</h4>
                    </figcaption>
                    <figcaption className="diagram-textbox mobile-left">
                      <h4 className="title smaller">{t`section_ekologia.supplier`}</h4>
                    </figcaption>
                    <figcaption className="diagram-textbox wide">
                      <h4 className="title smaller">{t`section_ekologia.panels`}</h4>
                    </figcaption>
                    <figcaption className="diagram-textbox desktop-upward wide">
                      <h4 className="title smaller">{t`section_ekologia.farms`}</h4>
                    </figcaption>
                    <figcaption className="diagram-textbox upward wide desktop-only">
                      <h4 className="title smaller">{t`section_ekologia.data`}</h4>
                    </figcaption>
                  </div>
                </figure>
                <div className="row">
                  <div className="column column-1">
                    <div className="text-box">
                      <h3 className="title smaller">{t`section_ekologia.app_focused`}</h3>
                      <p className="text">{t`section_ekologia.app_focused_desc`}</p>
                    </div>
                  </div>
                  <div className="column column-2">
                    <div className="text-box">
                      <p className="text">{t`section_ekologia.p1`}</p>
                      <p className="text">{t`section_ekologia.p2`}</p>
                      <p className="text">{t`section_ekologia.p3`}</p>
                    </div>
                  </div>
                </div>
                <div className="border-box" />
                <figure className="diagram diagram-ekologia mobile-only">
                  <img src="/src/img/Diagram3-bottom.png" className="diagram-image Diagram3-bottom"
                       alt="Diagram ekologia"/>
                  <div className="diagram-container">
                    <figcaption className="diagram-textbox upward wide">
                      <h4 className="title smaller">{t`section_ekologia.app_data`}</h4>
                    </figcaption>
                  </div>
                </figure>
                <div className="button-container">
                  <strong className="title">{t`section_ekologia.start_eco`}</strong>
                  <Link onClick={onNavClick} to="/contact" className="button">{t`section_ekologia.start`}</Link>
                </div>
              </div>
            </div>
          </section>
          <section className="section-design collapsible">
            <input type="checkbox" className="collapsible-checkbox" id="col-for-designers"/>
            <label htmlFor="col-for-designers" className="collapsible-header">
              <h2 className="title small">{t`section_design.expect_design`}</h2>
            </label>
            <div className="collapsible-container" ref={onCollapsibleRef}>
              <div className="collapsible-content">
                <div className="row">
                  <div className="column column-1">
                    <div className="text-box">
                      <h3 className="title smaller">{t`section_design.imagination`}</h3>
                      <p className="text">{t`section_design.imagination_desc`}</p>
                    </div>
                  </div>
                </div>
                <div className="design-customiser">
                  <input type="radio" name="customiser" id="customiser-pge" defaultChecked/>
                  <input type="radio" name="customiser" id="customiser-rzeszow"/>
                  <input type="radio" name="customiser" id="customiser-obrazek"/>
                  <div className="customiser-lines left desktop-only">
                    <div className="customiser-line"/>
                    <div className="customiser-line"/>
                    <div className="customiser-line"/>
                  </div>
                  <div className="customiser-options">
                    <div className="customiser-option customiser-option-pge">
                      <label className="customiser-logo" htmlFor="customiser-pge">
                        <img src="/src/img/PGE.jpg" alt="PGE"/>
                      </label>
                      <div className="customiser-lines right top">
                        <div className="customiser-line"/>
                        <div className="customiser-line"/>
                      </div>
                    </div>
                    <div className="customiser-option customiser-option-rzeszow">
                      <label className="customiser-logo" htmlFor="customiser-rzeszow">
                        <img src="/src/img/Rzeszow.jpg" alt="Miasto Rzeszów"/>
                      </label>
                      <div className="customiser-lines right straight">
                        <div className="customiser-line" />
                      </div>
                    </div>
                    <div className="customiser-option customiser-option-obrazek">
                      <label className="customiser-logo" htmlFor="customiser-obrazek">
                        <img src="/src/img/custom.png" alt="Design"/>
                      </label>
                      <div className="customiser-lines right btm">
                        <div className="customiser-line"/>
                        <div className="customiser-line"/>
                      </div>
                    </div>
                  </div>
                  <img src="/src/img/ladowarka-kolor.png" className="ladowarka-design ladowarka-kolor" alt="Ladowarka"/>
                  <img src="/src/img/ladowarka-rzeszow.png" className="ladowarka-design ladowarka-rzeszow"
                       alt="Ladowarka"/>
                  <img src="/src/img/ladowarka-custom.png" className="ladowarka-design ladowarka-custom"
                       alt="Ladowarka"/>
                </div>
                <div className="row">
                  <div className="column column-1">
                    <div className="text-box text-box-yours">
                      <h3 className="title smaller">{t`section_design.uncompromising`}</h3>
                      <p className="text">{t`section_design.uncompromising_desc`}</p>
                    </div>
                  </div>
                </div>
                <img src="/src/img/wallbox+szyba.png" className="wallboxszyba" alt="Wallbox"/>
                <div className="row">
                  <div className="column column-1" />
                  <div className="column column-2">
                    <div className="text-box text-box-glass">
                      <h3 className="title smaller">{t`section_design.replacable_glass`}</h3>
                      <p className="text">{t`section_design.wallbox_charger_has`}</p>
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <strong>{t`section_design.lets_create_charger`}</strong>
                  <Link onClick={onNavClick} to="/contact" className="button">{t`section_design.start`}</Link>
                </div>
              </div>
            </div>
          </section>
          <SectionSmartHome onCollapsibleRef={onCollapsibleRef} />
          <section className="section-ready">
            <div className="bg-container">
              <img src="/src/img/ladowarka-ciemna.jpg" className="ladowarka-ciemna" alt="Charger on dark background"/>
            </div>
            <div className="container">
              <div className="row">
                <div className="column column-1">
                </div>
                <div className="column column-2">
                  <div className="button-container wow fadeIn">
                    <strong className="title large" dangerouslySetInnerHTML={{__html: t`section_ready.u_ready` }} />
                    <Link onClick={onNavClick} to="/contact" className="button white">{t`section_ready.store`}</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-app">
            <h2 className="title large mobile-only" dangerouslySetInnerHTML={{__html: t`section_app.for_installation`}} />
            <div className="row">
              <div className="column column-2">
                <hr className="desktop-only"/>
                <img src="/src/img/komorka.png" className="apka wow fadeInLeft" alt="app"/>
              </div>
              <div className="column column-1">
                <h2 className="title large title-main desktop-only">{t`section_app.for_installation_desktop`}</h2>
                <div className="text-box">
                  <h4 className="title small">{t`section_app.easy_use`}</h4>
                  <p className="text">{t`section_app.our_app_designed`}</p>
                  <VideoButton/>
                  <div className="app-badges">
                    <a href="https://apps.apple.com/us/app/id1530978641" className="app-badge-appstore" target="_blank" rel="noreferrer">
                      <img src={t`section_app.badge_appstore`} alt={t`section_app.badge_appstore`}/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.friendlyinnovation.app"
                       className="app-badge-googleplay" target="_blank" rel="noreferrer">
                      <img src={t`section_app.badge_playstore`} alt={t`section_app.badge_playstore`}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-feedback">
            <div className="separator separator-klienci">
              <hr className="desktop-only"/>
              <h3 className="title large" dangerouslySetInnerHTML={{__html: t`section_feedback.title1`}} />
            </div>
            <Opinions/>
          </section>
          <section className="section-youready">
            <div className="button-container wow fadeIn">
              <strong className="title large">{t`section_feedback.u_ready`}</strong>
              <Link onClick={onNavClick} to="/contact" className="button">{t`section_feedback.store`}</Link>
            </div>
          </section>
          <section className="section-footer" itemScope itemType="http://schema.org/LocalBusiness">
            <div className="footer-contact">
              <div className="footer-contact-content">
                <div>
                  <strong>{t`section_footer.phone`}</strong><a href="tel:+48608687168"><span
                  itemProp="telephone">+48 608 687 168</span></a>
                </div>
                <div>
                  <strong>{t`section_footer.support`}</strong><a href="mailto:support@ecoplanet24.com">support@ecoplanet24.com</a>
                </div>
                <div>
                  <strong>{t`section_footer.cooperation`}</strong><a
                  href="mailto:ecoplanet24@friendlyinnovation.com">ecoplanet24@friendlyinnovation.com</a>
                </div>
              </div>
            </div>
            <div className="footer-logos">
              <a href="https://www.friendlyinnovation.com/" alt="Friendly Innovation">
                <img src="/src/img/fi-logo.png" alt="Friendlyinn"/>
              </a>
              <img src="/src/img/Eco-icon.svg" alt="Ecoplanet24" itemProp="image"/>
            </div>
            <div className="footer-flex">
              <div className="footer-address">
                <div className="footer-address-content">
                  <a href="https://goo.gl/maps/NAEvCX7EXXugKcoF6" className="footer-address-office" target="_blank"
                     rel="noreferrer">
                    {t`section_footer.office`}<br/>
                    <address className="ul">{t`section_footer.address`}</address>
                  </a>
                  <div className="footer-address-warsaw" dangerouslySetInnerHTML={{__html: t`section_footer.address_warsaw`}} />
                  <div className="footer-address-nip">
                    <span>DUNS&reg;:850702469</span>
                    <span>NIP:5252772029</span>
                    <span>REGON:381562330</span>
                    <span>KRS:0000759528</span>
                  </div>
                  <a href="mailto:office@friendlyinnovation.com"
                     className="footer-address-email">office@friendlyinnovation.com</a>
                </div>
              </div>
              <div className="footer-whataboutism">
                <div className="footer-whataboutism-content">
                  <p itemProp="name">Friendly Innovation sp. z o.o.</p>
                  <ul>
                    {aboutus.map(i => <li key={i} dangerouslySetInnerHTML={{__html: t(`section_footer.about_us_${i}`)}} />)}
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-flex">
              <div className="footer-nav-left">
                <div className="footer-nav-left-content">
                  <a href="https://www.friendlyinnovation.com/">Friendly Innovation</a>
                  <Link onClick={onNavClick} to="/app/privacy-policy/">{t`section_footer.privacy_policy`}</Link>
                  <Link onClick={onNavClick} to="/app/terms-of-service/">{t`section_footer.tos`}</Link>
                </div>
              </div>
              <div className="footer-nav-right">
                <div className="footer-nav-right-content">
                  <SmoothAnchor href="#top">{t`section_footer.up`}</SmoothAnchor>
                </div>
                <div className="footer-social">
                  <a href="https://www.instagram.com/friendly.innovation/" target="_blank" rel="noreferrer">
                    <img src="/src/img/sm/insta.png" alt="Instagram"/>
                  </a>
                  <a href="https://www.facebook.com/friendly.innovation/" target="_blank" rel="noreferrer">
                    <img src="/src/img/sm/iconfinder_Rounded_Facebook_svg_5282541.svg" alt="Facebook"/>
                  </a>
                  <a href="https://www.linkedin.com/company/friendly-innovation-sp-z-o-o/" target="_blank"
                     rel="noreferrer">
                    <img src="/src/img/sm/iconfinder_linked_in_104828.svg" alt="LinkedIn"/>
                  </a>
                  <a href="https://g.page/friendly-Innovation/" target="_blank" rel="noreferrer">
                    <img src="/src/img/sm/iconfinder_google_4691557.svg" alt="Google"/>
                  </a>
                  <a href="https://www.youtube.com/channel/UC3AogcMTLnmQ91GR7f4HYsQ" target="_blank" rel="noreferrer">
                    <img src="/src/img/sm/iconfinder_BW_Youtube_glyph_svg_5305164.svg" alt="YouTube"/>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Cookie />
      <aside className="overlay-bg animate fadeIn">
        <OverlayClose player={playerRef}/>
        <div className="overlay animate fadeInUp">
          <YouTube videoId={"sBrsQiSEtFI"}
                   id="player"
                   opts={{
                    autoplay: 0,
                    rel: 0,
                    showinfo: 0,
                    controls: 0,
                    modestbranding: 1
                  }}
                   onReady={event => playerRef.current = event.target}
                  />
        </div>
      </aside>
    </>
  );
}

export default Home;
