import { useState, useCallback, useMemo } from "react";
import {useSwipeable} from "react-swipeable";
import {useTranslation} from "react-i18next";

function Opinion({ id, img, author, city, name, description, product, price, currency, availability, hideImg, active = false }) {
  return <div className={`opinion ${active ? "active" : ""}`} id={`opinion-${id}`} itemProp="review" itemScope
              itemType="https://schema.org/Review">
    <div className="opinion-author">
      {!hideImg && <img src={`/src/img/opinions/${img}`} className="opinion-image" alt={author}/>}
      <div className="mobile-only">
        <strong itemProp="author">{name}</strong>
        <p itemProp="name">{author}, {city}</p>
      </div>
    </div>
    <div className="opinion-text">
      <p itemProp="description">{description}</p>
      <div className="desktop-only">
        <strong>{name}</strong>
        <p>{author}, {city}</p>
      </div>
    </div>
    <div itemProp="itemReviewed" itemScope itemType="https://schema.org/Product">
      <meta itemProp="name" content={product}/>
      <div itemProp="offers" itemType="https://schema.org/Offer" itemScope>
        <meta itemProp="price" content={price}/>
        <meta itemProp="priceCurrency" content={currency}/>
        <meta itemProp="availability" content={availability}/>
      </div>
    </div>
  </div>;
}

function NavItem({ id, img_small, author, city, onClick, hideImg, active = false, left = false }) {
  return <button className={`opinion-nav-item ${active ? "active" : ""} ${left ? "left" : ""}`} data-for={`opinion-${id}`} onClick={onClick}>
    {!hideImg && <img src={`/src/img/opinions/${img_small}`} alt={author}/>}
    <span>{author}, <span>{city}</span></span>
  </button>;
}

export function Opinions() {
  const { t } = useTranslation()

  const opinionsText = useMemo(() => ({
    michal: {
      hideImg: true,
      img: "Michal-big.png",
      img_small: "Michal-small.png",
      author: "Michał",
      city: "Tarnów",
      name: t`opinions.michal.name`,
      description: t`opinions.michal.description`,
      product: t`opinions.michal.product`,
      price: t`opinions.michal.price`,
      currency: t`opinions.michal.currency`,
      availability: "https://schema.org/InStock",
    },
    milosz: {
      img: "Milosz-big.png",
      img_small: "Milosz-small.png",
      author: "Miłosz",
      city: "Poznań",
      name: t`opinions.milosz.name`,
      description: t`opinions.milosz.description`,
      product: t`opinions.milosz.product`,
      price: t`opinions.milosz.price`,
      currency: t`opinions.milosz.currency`,
      availability: "https://schema.org/InStock"
    },
    jacek: {
      img: "064E838A-BD5B-4989-9DA5-ADF0730C4130.jpg",
      img_small: "064E838A-BD5B-4989-9DA5-ADF0730C4130.jpg",
      author: "Jacek",
      city: "Sandomierz",
      name: t`opinions.jacek.name`,
      description: t`opinions.jacek.description`,
      product: t`opinions.jacek.product`,
      price: t`opinions.jacek.price`,
      currency: t`opinions.jacek.currency`,
      availability: "https://schema.org/InStock"
    }
  }), [t])

  const [opinionState, setOpinionState] = useState([
    {
      id: "michal",
      active: true
    },
    {
      id: "milosz",
      active: false
    },
    {
      id: "jacek",
      active: false
    }
  ])

  const [rightSwipe, setRightSwipe] = useState(false)

  const activeNavItem = useMemo(() => opinionState.findIndex(opinion => opinion.active), [opinionState])

  const setActiveItem = useCallback((index) => {
    const nextNavItem = Math.min(opinionState.length - 1, Math.max(0, index));

    if (nextNavItem < activeNavItem) {
      setRightSwipe(true)
    } else if (nextNavItem > activeNavItem) {
      setRightSwipe(false)
    }

    setOpinionState(opinionState.map((opinion, i) => ({
      ...opinion,
      active: i === nextNavItem,
      left: i === nextNavItem - 1
    })))
  }, [activeNavItem, opinionState, setOpinionState])

  const onSwipe = useCallback((dir) => () => {
    if(matchMedia("screen and (max-width: 767px))")) {
      setActiveItem(activeNavItem + dir);
    }
  }, [setActiveItem, activeNavItem])

  const handlers = useSwipeable({
    onSwipedLeft: onSwipe(1),
    onSwipedRight: onSwipe(-1),
    delta: 100,
    trackMouse: true
  })

  const shownOpinions = useMemo(() => {
    return opinionState.map(({id, ...item}) => ({id, ...item, ...opinionsText[id]}))
  }, [opinionState, opinionsText])



  return <div className={`opinions${rightSwipe ? " right-swipe" : ""}`} {...handlers}>
    <div className="opinions-container">
      {shownOpinions.map(props => <Opinion key={props.id} {...props} />)}
    </div>
    <div className="opinion-nav">
      {shownOpinions.map((props, i) => <NavItem key={props.id} {...props} onClick={() => setActiveItem(i)} />)}
    </div>
  </div>;
}
