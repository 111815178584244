import {useTranslation} from "react-i18next";
import "./SectionSmartHome.css"
import BlueLink from "../BlueLink";

function IconWithBadge({ text, src, ...props }) {
  return <figure className="sh-icon" {...props}>
    <figcaption className="sh-badge">{text}</figcaption>
    <img src={src} alt={text}/>
  </figure>;
}

export default function SectionSmartHome({ onCollapsibleRef }) {
  const { t } = useTranslation()

  return (
    <section className="section-smarthome">
      <div className="flex sh-hero">
        <div>
          <hr className="smarthome-hr"/>
          <div className="flex sh-icons">
            <IconWithBadge src="/src/img/sh-building.png" text={t`section_smarthome.bms`}/>
            <IconWithBadge src="/src/img/sh-home.png" text={t`section_smarthome.smart_home`}/>
          </div>
        </div>
        <div className="sh-textbox">
          <h3 className="title large wow fadeInUp" style={{visibility: "visible"}}>{t`section_smarthome.title`}</h3>
          <h4 className="title small wow fadeInUp" style={{visibility: "visible"}}>{t`section_smarthome.subtitle`}</h4>
          <p className="text">{t`section_smarthome.desc`}</p>
        </div>
      </div>
      <div className="collapsible compact">
        <input type="checkbox" className="collapsible-checkbox" id="col-smarthome"/>
        <label htmlFor="col-smarthome" className="collapsible-button" />
        <div className="collapsible-container" ref={onCollapsibleRef}>
          <div className="collapsible-content">
            <div className="row">
              <div className="column column-1">
                <div className="sh-lines-container">
                  <div className="sh-lines-top"/>
                  <div className="sh-line-bottom">
                    <div className="sh-rsbox sh-badge" dangerouslySetInnerHTML={{__html: t`section_smarthome.rs485canhhtp`}} />
                  </div>
                </div>
                <img src="/src/img/charger.png" alt="Charger" className="sh-charger" />
              </div>
              <div className="column column-2">
                <div className="text-box">
                  <p className="text" dangerouslySetInnerHTML={{__html: t`section_smarthome.expandable_desc`}} />
                </div>
                <div className="grenton-container">
                  <p className="grenton-title" dangerouslySetInnerHTML={{__html: t`section_smarthome.ready_solutions`}} />
                  <div className="flex">
                    <img alt="Grenton" src="/src/img/grenton.png" />
                    <a href="https://gitlab.com/friendlyinn-community/" className="button sh-download" target="_blank" rel="noreferrer">{t`section_smarthome.download`}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sh-link-container">
        <BlueLink href="https://www.friendlyinnovation.com/smart-home/" icon="smarthome" target="_blank" rel="noreferrer">{t`section_smarthome.more`}</BlueLink>
      </div>
    </section>
  )
}